






































































































.auth-container {
  width: 460px;
  max-width: 100%;
  margin: 0 auto;
  margin-top: 15vh;
  .custom-control-label {
    &:before,
    &:after {
      width: 20px;
      height: 20px;
      top: 0px;
    }
  }

  .input-group-text {
    padding: 5px 10px;
  }
}
